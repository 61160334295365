import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';

export const setupAppInsights = (connectionString: string) => {
	const browserHistory = typeof document !== 'undefined' ? createBrowserHistory() : null;

	let appInsights = null;

	if (typeof document !== 'undefined') {
		// Ensure it's only run on the client side
		const reactPlugin = new ReactPlugin();

		appInsights = new ApplicationInsights({
			config: {
				connectionString,
				extensions: [reactPlugin],
				extensionConfig: {
					[reactPlugin.identifier]: { history: browserHistory },
				},
			},
		});

		appInsights.loadAppInsights();
	}
};
