import { useHistory } from 'application/contexts/HistoryContext';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useState } from 'react';
import { AdobeTracking } from './Adobe';
import { setupAppInsights } from './AppInsights/setupAppInsights';
import { Chatbot } from './Chatbot/Chatbot';
import { CludoInit, CludoLang, getCludoEngineId } from './Cludo';
import { CookieConsent, CookieConsentTypes } from './CookieConsent';

export const Vendors: React.FC<Content.PageContent['page']> = ({ documentType, properties }) => {
	const { chatbotTenant } = properties ?? {};
	const siteContext = useSiteContext();
	const pageContext = usePageContext();
	const { locale: language, asPath: urlPath } = useRouter();
	const { history } = useHistory();

	const adobeDefaultActive = false; // Used for testing. It should always be false

	const [enabledSearchPage, setEnabledSearchPage] = useState(null);
	const [adobeTrackingActive, setAdobeTrackingActive] = useState(adobeDefaultActive);
	const [statisticalCookiesAllowed, setStatisticalCookiesAllowed] = useState(false);

	const { host, chatbotConfiguration } = pageContext ?? {};
	const {
		APP_ENV: environment,
		DOMAIN_PROTOCOL: protocol,
		COOKIE_CONSENT: cookieConsentActive,
		APPINSIGHTS_CONNECTION_STRING: appInsightsConnectionString,
	} = getConfig()?.publicRuntimeConfig ?? {};

	const hostUrl = `${protocol ? `${protocol}://` : ''}${host}`;

	const { settings: siteSettings, theme } = siteContext ?? {};
	const { searchConfiguration, applicationInsights, adobeTracking, segmentAreas, chatbot } = siteSettings ?? {};
	const { searchPage, searchEnabled, searchOverwriteEngine } = searchConfiguration ?? {};

	if (searchPage && searchEnabled && !enabledSearchPage) setEnabledSearchPage(searchPage);
	const lang = language?.split('-')[0] as CludoLang;

	const loadCludoConfig = () => {
		CludoInit({
			searchPage: enabledSearchPage,
			language: lang,
			hideSearchFilters: !segmentAreas?.segmentsEnabled,
			engineId: searchOverwriteEngine ? searchOverwriteEngine : getCludoEngineId(theme, lang),
		});
	};

	const handleConsent = (consent: CookieConsentTypes) => {
		setStatisticalCookiesAllowed(consent.statistic);
		consent.statistic && adobeTracking?.active ? setAdobeTrackingActive(true) : setAdobeTrackingActive(false);
	};
	const { chatbotOnly, settingsId, tenantId } = chatbotTenant?.properties ?? { chatbotOnly: false };
	const { chatbotId } = settingsId?.properties ?? {};

	useEffect(() => {
		if (!applicationInsights?.active) return;
		if (!appInsightsConnectionString) return console.error('AppInsights: Connection string missing');
		if (statisticalCookiesAllowed) setupAppInsights(appInsightsConnectionString);
	}, [appInsightsConnectionString, statisticalCookiesAllowed, applicationInsights]);

	return (
		<>
			{searchPage && searchEnabled && <Script src="/js/cludo.js" onLoad={() => loadCludoConfig()} />}
			{adobeTrackingActive && (
				<AdobeTracking
					pageContext={pageContext}
					siteContext={siteContext}
					language={language}
					documentType={documentType}
					theme={theme}
					environment={environment}
					currentUrl={hostUrl + urlPath}
					previousUrl={history?.previous ? hostUrl + history.previous : 'null'}
					defaultActive={adobeDefaultActive}
				/>
			)}
			{chatbot?.active && (
				<Chatbot
					tenantId={chatbotOnly ? '11' : tenantId}
					settingsId={chatbotId}
					chatbotConfiguration={chatbotConfiguration}
					chatIntegrityNew={chatbot?.chatIntegrityKeys?.properties?.chatIntegrityNew}
					chatIntegrityOld={chatbot?.chatIntegrityKeys?.properties?.chatIntegrityOld}
				/>
			)}

			{cookieConsentActive && <CookieConsent consentCallBack={(consent) => handleConsent(consent)}></CookieConsent>}
		</>
	);
};
